import { MonetizationOnOutlined, UploadOutlined,Approval ,Apps,FindInPageOutlined,CompareArrowsOutlined,AttachMoney} from '@mui/icons-material';
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";


const Item = ({ title, to, icon, selected, setSelected, description }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Tooltip title={description}>
        <Typography>{title}</Typography>
        <Link to={to} />
      </Tooltip>

    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("dashboard");
  const [user, setUser] = useState({});
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      setUser(user);
    }
    else {
      navigate(`/`);
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <img
                      alt="profile-user"
                      width="100px"
                      height="100px"
                      src={`../../assets/logo_tropicalia.png`}
                      style={{ cursor: "pointer", borderRadius: "50%" }}

                    />
                  </Box>
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {user && user.profiles && (user.profiles.includes('ADMIN') || user.profiles.includes('FINANCEIRO') || user.profiles.includes('COBRANCA')) ?
              <>
                <Item
                  title="HOME"
                  description="Tela inicial do sistema"
                  to="/invoices"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}

                />
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Financeiro
                </Typography>
                <Item
                  title="Usuárias"
                  description="Lista de usuárias do Sistema"
                  to="/usuarios"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Busca Novas Usuárias"
                  description="Lista de usuárias do Sistema"
                  to="/usuaria-busca-cnpj"
                  icon={<FindInPageOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="AVC x API"
                  description="Gestão de Recebimento AVC"
                  to="/avc-x-ons"
                  icon={<CompareArrowsOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Recebimento"
                  description="Gestão de Recebimento AVC"
                  to="/invoices"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Extratos"
                  description="Controle de extratos"
                  to="/extratos"
                  icon={<MonetizationOnOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Cobrança"
                  description="Gestão Cobrança e devedores"
                  to="/inadimplencia"
                  icon={<AttachMoney />}
                  selected={selected}
                  setSelected={setSelected}
                /></> : <></>}
            {user && user.profiles && (user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTOS')) ?
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  TI - Cadastros
                </Typography>
                <Item
                  title="Login"
                  description="Lista de Logins de Sistema"
                  to="/logins"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Novo Login"
                  description="Cadastro de Logins de Sistema"
                  to="/user-create-form"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  TI - Integração Site
                </Typography>
                <Item
                  title="Upload de Documentos"
                  description="Upload de Documentos, XML DANFE e Boletos"
                  to="/arquivos"
                  icon={<UploadOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Gerenciamento Docs"
                  description="Gerenciamento de Documentos, XML DANFE e Boletos"
                  to="/arquivos-sistema"
                  icon={<Apps />}
                  selected={selected}
                  setSelected={setSelected}
                />
                  <Item
                  title="Integração Site"
                  description="Integração Site usuárias"
                  to="/site"
                  icon={<Approval />}
                  selected={selected}
                  setSelected={setSelected}
                />
                </>
              : <></>}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;