import React, { useEffect, useState, } from 'react';
import { Card, CardContent, Typography, Box, useTheme, Backdrop, CircularProgress, Grid, Button, Modal, TextField } from "@mui/material";
import { tokens } from "../../theme";
import { NumericFormat } from 'react-number-format';
import Header from "../../components/Header";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { NotificationAddOutlined, AppRegistration, RadioButtonChecked } from '@mui/icons-material';

const InvoicesDetailAvc = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [datetime, setDatetime] = useState(new Date().toISOString().slice(0, 16));
  const [file, setFile] = useState(null);
  const [valorExtrato, setValorExtrato] = useState("");
  const [justificativa, setJustificativa] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyIdentify = searchParams.get('cnpj');
  const ano = searchParams.get('ano');
  const mes = searchParams.get('mes');
  const id = searchParams.get('id');
  const numeroAvc = searchParams.get('numeroAvc');
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleFileChange = (event) => setFile(event.target.files[0]);

  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
        prefix="R$ "
      />
    );
  };

  const handleSubmit = () => {
    console.log({ datetime, file, valorExtrato, justificativa });
    handleCloseModal();
  };

  const renderFieldExtrato = (label, value) => (
    <Grid item xs={4}>
      <Typography variant="body2" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="body1">
        {value !== null && value !== undefined ? value.empresa : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Valor: {value !== null && value !== undefined ? value.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Agência: {value !== null && value !== undefined ? value.agencia : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Conta: {value !== null && value !== undefined ? value.conta : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Documento: {value !== null && value !== undefined ? value.documento : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Linha do Arquivo: {value !== null && value !== undefined ? value.line : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Arquivo: {value !== null && value !== undefined ? value.fileName : 'N/A'}
      </Typography>
    </Grid>
  );

  const renderField = (label, value) => (
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="body1">
        {value !== null && value !== undefined ? value : 'N/A'}
      </Typography>
    </Grid>
  );

  const renderFieldTupla = (label, value) => (
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="body1">
        {value !== null && value !== undefined ? value : 'N/A'}
      </Typography>
    </Grid>
  );

  const renderFieldLink = (label, value, link) => (
    <Grid item xs={6}>
      <Typography variant="h6">{label}:</Typography>
      <Typography variant="body1">
        <Link to={`${link}`}>{value}</Link>
      </Typography>

    </Grid>

  );

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('FINANCEIRO')
          || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        fetch(`${apiHost}/service/avc/id?onsCode=1257&ano=${ano}&numeroAvc=${numeroAvc}&mes=${mes}&companyIdentify=${companyIdentify}&id=${id}&`
          , {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
          .then(response => response.json())
          .then(data => {
            setData(data);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }

  }, [apiHost, ano, mes, numeroAvc, companyIdentify, id, navigate]);

  return (
    <Box m="20px">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Header title="Detalhes AVC e ONS" subtitle="Detalhe da de carregamento de dados" />
        </Grid>
        <Grid item xs={2}>
          {data?.status === 'Atraso' && (
            <Button
              sx={{
                backgroundColor: colors.redAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "10px",
              }}
            >
              <NotificationAddOutlined sx={{ mr: "10px" }} />
              Aviso de Debito
            </Button>)}
        </Grid>
        <Grid item xs={2}>
          {data?.status === 'Atraso' && (

            <Button
              sx={{
                backgroundColor: colors.redAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: "10px"
              }}
            >
              <AppRegistration sx={{ mr: "10px" }} />
              Negativar ONS
            </Button>

          )}
        </Grid>
        <Grid item xs={3}>
          {data?.status === 'Atraso' && (
            <Button sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], padding: "10px 20px" }} onClick={handleOpenModal}>
              <RadioButtonChecked sx={{ mr: "10px" }} />
              Conciliar Manualmente
            </Button>
          )}
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box m="10px 0 0 0" height="75vh" sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .no-border-bottom": {
          borderBottom: "none !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}>

        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {renderField('Meu Número Ons', data?.onsCode)}
              {renderField('Mês', data?.mes)}
              {renderField('Ano', data?.ano)}
              {renderField('Usuária', data?.idUsuaria)}

            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {renderField('Número AVC', data.numeroAvc)}
              {renderField('ONS Code', data.onsCode)}
              {renderField('Transmissoras', data.transmissoras)}
              {renderField('Código Radar', data?.codigoNovoRadar)}
              {renderField('CNPJ', data.cnpj)}
              {renderField('Rede Básica', data.redeBasica ? data.redeBasica.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Total de Fronteira', data.totalDeFronteira ? data.totalDeFronteira.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Quota Proinfa', data.quotaProinfa ? data.quotaProinfa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Quota CCC Sistema', data.quotaCCCSistema ? data.quotaCCCSistema.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Quota CCC Sistema Isolado', data.quotaCCCSistemaIsolado ? data.quotaCCCSistemaIsolado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Quota CDE', data.quotaCDE ? data.quotaCDE.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Total sem Pis/Pasep', data.totalSemPisPasep ? data.totalSemPisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Valor Total', data.valorTotal ? data.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Pis/Pasep', data.pisPasep ? data.pisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Total com Pis/Pasep', data.totalComPisPasep ? data.totalComPisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Número ONS', data.numeroOns)}
              {renderField('Status', data.status)}
              {renderField('Período Contábil', data.periodoContabilInt)}
            </Grid>
          </CardContent>
        </Card>
        <br />

        <Card style={{ backgroundColor: data.status === 'Atraso' ? "red" : data.status === 'Aguardando' ? colors.blueAccent[800] : 'green' }}>
          <CardContent >
            <Grid container spacing={2} >
              {renderFieldTupla('Data Parcela dia 15', data.dataParcela1)}
              {renderFieldTupla('Valor Parcela dia 15', data.valorParcela1 ? data.valorParcela1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Dias Atraso dia 15', data.diasAtraso1)}
              {renderField('Status', data.status1)}
              {renderFieldTupla('Data Parcela dia 25', data.dataParcela2)}
              {renderFieldTupla('Valor Parcela dia 25', data.valorParcela2 ? data.valorParcela2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Dias Atraso dia 25', data.diasAtraso2)}
              {renderField('Status', data.status2)}
              {renderFieldTupla('Data Parcela dia 05', data.dataParcela3)}
              {renderFieldTupla('Valor Parcela dia 05', data.valorParcela3 ? data.valorParcela3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Dias Atraso dia 05', data.diasAtraso3)}
              {renderField('Status', data.status3)}
            </Grid>

          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {renderFieldExtrato('Extrato dia 15', data.extrato15)}
              {renderFieldExtrato('Extrato dia 25', data.extrato25)}
              {renderFieldExtrato('Extrato dia 05', data.extrato05)}
            </Grid>

          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {renderFieldLink('Danfe', data.invoice ? data.invoice.nfeDto.nfeKey : "", data.invoice ?
                data.invoice.nfeDto.danfeUrl : "")}
              {renderFieldLink('XML', data.invoice ? data.invoice.nfeDto.nfeKey : "", data.invoice ? data.invoice.nfeDto.xmlUrl : "")}
            </Grid>
            {data.invoice && data.invoice.ticketDtoList && data.invoice.ticketDtoList.map((ticket, index) => (
              <Grid item xs={12} key={index}>
                {renderFieldLink('Boleto', ticket.barCode, ticket.url)}
              </Grid>
            ))}

          </CardContent>
        </Card>

      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography id="modal-title" variant="h6" component="h2">Conciliar Manualmente</Typography>
          <TextField fullWidth margin="normal" label="Data e Hora" type="datetime-local" value={datetime} onChange={(e) => setDatetime(e.target.value)} />
          <TextField fullWidth margin="normal" label="Upload de Arquivo" type="file" onChange={handleFileChange} />
          <TextField fullWidth margin="normal" label="Valor do Extrato"
            value={valorExtrato} onChange={(e) => setValorExtrato(e.target.value)}                 
          />
          <TextField fullWidth margin="normal" label="Justificativa" multiline rows={4} value={justificativa} onChange={(e) => setJustificativa(e.target.value)} />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" color="secondary" onClick={handleSubmit} >Enviar</Button>
            <Button variant="contained" color="primary" onClick={handleCloseModal} >Cancelar</Button>
          </Box>
        </Box>
      </Modal>
    </Box >
  );
};

export default InvoicesDetailAvc;
