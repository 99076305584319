import React, { useState } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const imageLeft = "../../assets/left-financial.svg"
    const logo = "../../assets/logo_tropicalia.png";
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const apiHost = process.env.REACT_APP_API_URL_LOGIN || "http://localhost:8081";
    const handleFormSubmit = async (values, helpers) => {

        await fetch(`${apiHost}/usuario-login/fogotpassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: values.email,
        })
            .then((response) => {
                if (response.ok) {
                    setSuccessMessage("Você receberá uma nova senha no email, caso esteja cadastrado");
                    setTimeout(() => {
                        navigate(`/`);
                    }, 5000);
                }
            })
            .catch((error) => {
                setErrorMessage("Erro ao atualizar usuário");
                console.error(error)

            })
            .catch(new Error("Erro ao atualizar usuário"));
    };


    return (
        <Box m="20px" >
            <img src={logo} alt="logo" className="mt-16 ml-16 w-40" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="flex"
                            gap="30px"
                            flexDirection="column"
                            alignItems="center"

                            sx={{
                                "& > div": { width: "100%", maxWidth: "400px" }
                            }}
                        >

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <Button type="submit" color="secondary" variant="contained">
                                Recuperar Senha
                            </Button>

                            <Button type="submit" color="secondary" variant="contained"
                                disabled={!successMessage || !errorMessage}>
                                {successMessage && <div>{successMessage}</div>}
                                {errorMessage && <div>{errorMessage}</div>}
                            </Button>

                        </Box>


                    </form>
                )}
            </Formik>
            <img src={imageLeft} alt="left-financial" className="absolute bottom-0 left-0 w-[30rem]" />
        </Box>

    );
};

const initialValues = {
    email: ""
};

export default ForgotPassword;
