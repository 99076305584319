import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const SingIn = () => {
  const imageLeft = "../../assets/left-financial.svg"
  const logo = "../../assets/logo_tropicalia.png";
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {

  }, [navigate]);

  const encryptPayload = (payload, secretKey) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(payload), secretKey).toString();
    return ciphertext;
  };

  const saveToSession = (key, data) => {
    sessionStorage.setItem(key, data);
  };

  const handleFormSubmit = async (values, helpers) => {
    const apiHost = process.env.REACT_APP_API_URL_LOGIN;
    console.log(apiHost);
  
    try {

      const response = await fetch(`${apiHost}/usuario-login/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          documento: values.documento,
          senha: values.senha,
        }),
      });
  
  
      if (!response.ok) {
        throw new Error('Login inválido, tente novamente');
      }
  
      const userData = await response.json(); 
      const encryptedPayload = encryptPayload(userData, secretKey);
      saveToSession('session', encryptedPayload);
      setSuccessMessage('Login realizado com sucesso, você será redirecionado');  
      setTimeout(() => {
        if (userData.profiles.includes('ADMIN') || userData.profiles.includes('FINANCEIRO') || userData.profiles.includes('COBRANCA')) {
          navigate('/invoices');
        } else if (userData.profiles.includes('DOCUMENTOS')) {
          navigate('/arquivos');
        } else {
          navigate('/404');
        }
      }, 2000);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || 'Erro ao de sistema tente novamente usuário');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (

    <Box m="20px" >
      <img src={logo} alt="logo" className="mt-16 ml-16 w-40" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="30px"
              flexDirection="column"
              alignItems="center"

              sx={{
                "& > div": { width: "100%", maxWidth: "400px" }
              }}
            >

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Login"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.documento}
                name="documento"
                error={!!touched.documento && !!errors.documento}
                helperText={touched.documento && errors.documento}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="senha"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senha}
                name="senha"
                error={!!touched.senha && !!errors.senha}
                helperText={touched.senha && errors.senha}
                sx={{ gridColumn: "span 2" }}
              />
              <Button type="submit" color="secondary" variant="contained">
                Entrar no Sistema
              </Button>
              <Link to="/user-forgot-password" className="place-self-end hover:text-[#2347C5] hover:underline">
                <p className="text-[#5473E3]">Esqueceu a senha</p>
              </Link>
              <Button type="submit" color="secondary" variant="contained"
                disabled={!successMessage || !errorMessage}>
                {successMessage && <div>{successMessage}</div>}
                {errorMessage && <div>{errorMessage}</div>}
              </Button>
            </Box>


          </form>
        )}
      </Formik>
      <img src={imageLeft} alt="left-financial" className="absolute bottom-0 left-0 w-[30rem]" />
    </Box>

  );
};

const initialValues = {
  documento: "",
  senha: ""
};


export default SingIn;