import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select, Typography, useTheme, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { OverviewTasksProgress } from '../../components/OverviewTasksProgress';
import { OverviewTotalProfit } from '../../components/OverviewTotalProfit';
import { tokens } from "../../theme";

const Invoices = () => {

  const [valores, setValores] = useState([]);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";

  const [selectedPeriod, setSelectedPeriod] = useState(`0`);
  const [selectedStatus, setSelectedStatus] = useState(`status`);
  const [selectedParcela, setSelectedParcela] = useState(`0`);

  const handlePeriodChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        setSelectedPeriod(e.target.value);

        setLoading(true);
        const parametroParcela = selectedParcela === 0 ? `parcela` : `parcela=${selectedParcela}`;
        const parametroStatus = selectedStatus === 'status' ? `status` : `status=${selectedStatus}`;
        const partes = e.target.value.split('-');

        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&${parametroStatus}&${parametroParcela}`, {

          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleStatusChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        setSelectedStatus(e.target.value);
        const parametroParcela = selectedParcela === 0 ? `parcela` : `parcela=${selectedParcela}`;
        const parametroStatus = e.target.value === 'status' ? `status` : `status=${e.target.value}`;
        const partes = selectedPeriod.split('-');

        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&${parametroStatus}&${parametroParcela}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleParcelaChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        setSelectedParcela(e.target.value);
        const parametroParcela = e.target.value === 0 ? `parcela` : `parcela=${e.target.value}`;
        const parametroStatus = selectedStatus === 'status' ? `status` : `status=${selectedStatus}`;
        const partes = selectedPeriod.split('-');

        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&${parametroStatus}&${parametroParcela}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleClick = (item) => {
    navigate(`/invoices-detail?cnpj=${item.cnpj}&numeroOns=${item.idUsuaria}`);
  };

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };


  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('FINANCEIRO')
          || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
      }
      else {

        setLoading(true);
        const today = new Date();
        let mes = today.getMonth();
        let ano = today.getFullYear();

        fetch(`${apiHost}/service/avc?ano=${ano}&mes=${mes}&numeroOns=1257&status`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [apiHost, navigate]);


  const columns = [
    { field: "idUsuaria", headerName: "Codigo Ons" },
    { field: "codigoNovoRadar", headerName: "Codigo Radar" },
    {
      field: "transmissoras",
      headerName: "Usuário",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.cnpj ? params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5'): "00.000.000/0000-00"}
        </Typography>
      ),
    },
    {
      field: "15",
      headerName: "Dia 15",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography color={params.row.status1 === 'Aguardando' ? colors.blueAccent[500] : params.row.status1 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
            {params.row.valorParcela1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Box>
      ),
    },
    {
      field: "25",
      headerName: "Dia 25",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography color={params.row.status2 === 'Aguardando' ? colors.blueAccent[500] : params.row.status2 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
            {params.row.valorParcela2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Box>
      ),
    },
    {
      field: "05",
      headerName: "Dia 05",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography color={params.row.status3 === 'Aguardando' ? colors.blueAccent[500] : params.row.status3 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
            {params.row.valorParcela3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Box>
      ),
    },

    {
      field: "pisPasep",
      headerName: "Tributos",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.pisPasep !== null ? params.row.pisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "totalComPisPasep",
      headerName: "Valor Total",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status Faturamento",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.row.isNewUG ? "secondary" : params.row.status === 'Aguardando' ? "warning" : params.row.status === 'Atraso' ? "error" : "success"}
          onClick={() => handleClick(params.row)}
        >
          {params.row.isNewUG ? "Iniciado " : params.row.status}
        </Button>
      ),
    },
  ];

  return (
    <Box m="30px">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Header title="Faturamento AVC" subtitle="Lista de Valores a Receber" />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="period-label">Período Contábil</InputLabel>
            <Select
              label="Período Contábil"
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={handlePeriodChange}
            >
              <MenuItem value="0">Período Contábil ...</MenuItem>
              {years.slice().sort((a, b) => b - a).map(year => (
                months.map(month => (
                  <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                    {`${month.label} ${year}`}
                  </MenuItem>
                ))
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="period-label">Parcela</InputLabel>
            <Select
              label="Parcela"
              labelId="period-label"
              id="period"
              value={selectedParcela}
              onChange={handleParcelaChange}
            >
              <MenuItem value="0">Parcela ...</MenuItem>
              <MenuItem value="1">Parcela dia 15</MenuItem>
              <MenuItem value="2">Parcela dia 25</MenuItem>
              <MenuItem value="3">Parcela dia 05</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="period-label">Status</InputLabel>
            <Select
              label="Status"
              labelId="period-label"
              id="period"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="status">Status ...</MenuItem>
              <MenuItem value="Atraso">Atraso</MenuItem>
              <MenuItem value="Aguardando">Aguardando</MenuItem>
              <MenuItem value="Finalizado">Finalizado</MenuItem>

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalRecebidoPerCent === 'number' ? data.totalRecebidoPerCent.toFixed(2) : 0}
                title="Recebido"
              />
            </Box>
            <Box mr={1}>
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalAtrasoPercent === 'number' ? data.totalAtrasoPercent.toFixed(2) : 0}
                title="Atrasado"
              />
            </Box>
            <Box mr={1}>
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalAguardandoPercent === 'number' ? data.totalAguardandoPercent.toFixed(2) : 0}
                title="Aguardando"
              />
            </Box>
            <Box mr={1}>
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalEmitido === 'number' ? data.totalEmitido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="Total Emitido"
              />
            </Box>
            <Box mr={1}>
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalRecebido === 'number' ? data.totalRecebido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="Recebido"
                color="#fff"
              />
            </Box>
            <Box mr={1}>
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalAguardando === 'number' ? data.totalAguardando.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="A Receber"
                color="#fff"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        m="10px 0 0 0"
        height="90vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={valores}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          rowHeight={80}
        />
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box >
  );
};

export default Invoices;
