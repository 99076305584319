import React, { useState, } from 'react';
import { Card, CardContent, Typography, Box, useTheme, Backdrop, CircularProgress, Grid, TextField, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import InputMask from 'react-input-mask';
import SearchIcon from '@mui/icons-material/Search';

const CnpjInformation = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();
  const [cnpj, setCnpj] = useState('');


  const renderField = (label, value) => (
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="body1">
        {value !== null && value !== undefined ? value : 'N/A'}
      </Typography>
    </Grid>
  );

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const handleSearch = () => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('FINANCEIRO')
          || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        fetch(`${apiHost}/service/usuarios/cnpj?cnpj=${cnpj}`
          , {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }

          }
        )
          .then(response => response.json())
          .then(data => {
            setData(data);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }

  };

  return (
    <Box m="20px">
      <Header title="Detalhe AVC" subtitle="Detalhe da Cobrança" />
      <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <InputMask
          mask="99.999.999/9999-99"
          value={cnpj}
          onChange={(e) => setCnpj(e.target.value)}
        >
          {(inputProps) => <TextField {...inputProps} label="CNPJ" fullWidth />}
        </InputMask>

        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={handleSearch}
        >    <SearchIcon /></Button>
      </Box>
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Box m="40px 0 0 0" height="75vh" sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .no-border-bottom": {
          borderBottom: "none !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {renderField('Razão Social', data?.razao_social)}
              {renderField('Nome Fantasia', data?.nome_fantasia)}
              {renderField('CNPJ', data?.cnpj ? data?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5') : "")}
              {renderField('Natureza Juridica', data?.natureza_juridica)}
              {renderField('Codigo Natureza codigo_natureza_juridica', data?.natureza_juridica)}
              {renderField('CNAE Fiscal', data?.cnae_fiscal_descricao)}
              {renderField('CNAE Fiscal Código', data?.cnae_fiscal)}
              {renderField('Inicio de Atividade', data?.data_inicio_atividade)}
              {renderField('Identificação Matriz Filial', data?.descricao_identificador_matriz_filial)}
              {renderField('Codigo Porte', data?.codigo_porte)}
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {renderField('País', data?.pais)}
              {renderField('Estado', data?.uf)}
              {renderField('CEP', data?.cep)}
              {renderField('Logradouro', data?.logradouro)}
              {renderField('Municipio', data?.municipio)}
              {renderField('Bairro', data?.bairro)}
              {renderField('Número', data?.numero)}
              {renderField('Complemento', data?.complemento)}
              {renderField('Capital Social', data?.capital_social ? data?.capital_social.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Telefone', data?.ddd_telefone_1)}
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            {data.cnaes_secundarios && data.cnaes_secundarios.map((cnae, index) => (
              <Grid item xs={12} key={index}>
                {renderField('CNAES Codigo', cnae.codigo)}
                {renderField('CNAES Descrição', cnae.descricao)}
              </Grid>
            ))}

          </CardContent>
        </Card>

      </Box>
    </Box >
  );
};

export default CnpjInformation;
