
import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';

const PageNotFound = () => {
  const notFout404 = "../../assets/404.jpeg";

  return (
    <><Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                mb: 3,
                textAlign: 'center'
              }}
            >
              <img
                alt="Under development"
                src={notFout404}
                style={{
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: 400
                }} />
            </Box>
            <Typography
              align="center"
              sx={{ mb: 3 }}
              variant="h3"
            >
              404: Erro de Processo, você pode voltar a pagina anterior ou contactar o suporte
            </Typography>

            <Button             
              href="/invoices"
              startIcon={(
                <SvgIcon fontSize="small">
                  <ArrowLeftIcon />
                </SvgIcon>
              )}
              sx={{ mt: 3 }}
              variant="contained"
            >
             Voltar para faturamento
            </Button>
          </Box>
        </Container>
      </Box></>
  )
};

export default PageNotFound;
