import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import { Backdrop, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import CryptoJS from 'crypto-js';

const Usuarios = () => {

  const [data, setData] = useState([]);

  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const ownerCompany = process.env.REACT_APP_API_URL_COMPANY_CNPJ || "24870139000405";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const [user, setUser] = useState({});
  const navigate = useNavigate();


  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);

   await fetch(`${apiHost}/service/usuarios/upload?cnpj=${ownerCompany}`, {
      headers: {
        "Authorization": `Bearer ${user.token}`,
        'Access-Control-Allow-Origin': '*'
      },
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return null;
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          setData(data);
        }
      })
      .catch(error => {
        console.error('Erro ao enviar arquivo:', error);
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleUploadRadar = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);

   await fetch(`${apiHost}/service/usuarios/upload-radar?cnpj=${ownerCompany}`, {
      headers: {
        "Authorization": `Bearer ${user.token}`,
        'Access-Control-Allow-Origin': '*'
      },
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return null;
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          setData(data);
        }
      })
      .catch(error => {
        console.error('Erro ao enviar arquivo:', error);
      }).finally(() => {
        setLoading(false);
      });
  };



  const fileInputRef = useRef(null);

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleClick = (item) => {
    navigate(`/usuarios-detail?cnpj=${item.cnpj}`);
  };

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      setUser(user);
      if (!user || !user.profiles || !(user.profiles.includes('ADMIN'))) {
        navigate('/404');
        return;
      }

      setLoading(true);
      fetch(`${apiHost}/service/usuarios`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
          'Access-Control-Allow-Origin': '*'
        },
      })
        .then(response => {
          if (response.status === 401) {
            alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/');
            return null;
          }
          return response.json();
        })
        .then(data => {
          if (data) {
            setData(data);
          }
        })
        .catch(error => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, apiHost]);


  const columns = [{ field: "codigo", headerName: "codigo", flex: 0.5 },
    { field: "codigoNovoRadar", headerName: "Código Radar", flex: 2 },
  { field: "siglaAgente", headerName: "Sigla", flex: 2, },
  {
    field: "cnpj", headerName: "CNPJ", type: "text", headerAlign: "left", align: "left", flex: 1, renderCell: (params) => (
      <Typography >
        {params.row.cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
          '$1.$2.$3/$4-$5')}
      </Typography>
    ),
  },
  
  { field: "razaoSocial", headerName: "Razão Social", flex: 2 },
  { field: "contrato", headerName: "Contrato", flex: 1 },
  {
    field: "action",
    headerName: "Ação",
    flex: 1,
    renderCell: (params) => (
      <Button
        variant="contained"
        color={params.row.isNewUG ? "secondary" : "primary"}
        onClick={() => handleClick(params.row)}
      >
        {params.row.isNewUG ? "Nova UG " : "Ver Detalhes"}
      </Button>
    ),
  },];

  return (
    <Box m="20px">
      <Header
        title="Usuários do Sistema AMSE"
        subtitle="Lista de Usuárias do Sistema de Transmissão"
      />
      <div>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",

              }}
              onClick={handleClickUpload}
            >
              <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUpload} />
              Upload Arquivo de Usuários
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleUpload}
            />
          </Box>    
          <Box mr={2}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",

              }}
              onClick={handleClickUpload}
            >
           <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUploadRadar} />
              Upload codigos Radar
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleUploadRadar}
            />
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          getRowId={(row) => row.codigo}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Usuarios;
