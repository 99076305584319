import React from 'react';
import { Box } from "@mui/material";
import Team from "./scenes/logins";
import { useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Usuarios from "./scenes/usuarios";
import Extratos from "./scenes/extratos";
import Sidebar from "./scenes/global/Sidebar";
import Invoices from "./scenes/invoices";
import InvoicesDetail from "./scenes/invoices-detail";
import FormEdit from "./scenes/user-form";
import Form from "./scenes/user-create-form";
import FAQ from "./scenes/faq";
import Inadimplencia from './scenes/inadimplencia';
import UsuariosDetail from './scenes/usuarios-detail';
import InvoicesDetailAvc from './scenes/invoices-detail-avc';
import RouterError from './components/RouterError';
import SignIn from './scenes/user-login';
import ForgotPassword from './scenes/user-forgot-password';
import Arquivos from './scenes/arquivos';
import ArquivosSite from './scenes/arquivos-site';
import ArquivosSiteUsuarias from './scenes/arquivos-site-usuarias';
import ArquivosDetalhe from './scenes/arquivo-detail';
import PageNotFound from './scenes/404';
import CnpjInformation from './scenes/usuarios-cnpj';
import InvoicesAvcXOns from './scenes/avc-x-ons';


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'false';
  const location = useLocation();
  const isLoginPage = location.pathname === '/' || location.pathname === '/user-login';
  const isForgetPage = location.pathname === '/user-forgot-password';

  return (

    isLoggedIn || isLoginPage ? <SignIn /> :
    isForgetPage ? <ForgotPassword /> :
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Topbar setIsSidebar={setIsSidebar} />
            <main className="content" style={{ display: "flex" }}>
              {isSidebar && <Sidebar isSidebar={isSidebar} />}
              <Box flexGrow={1}>
                <Routes>
                  <Route path="/" element={<SignIn />} errorElement={<RouterError />} />
                  <Route path="/user-login" element={<SignIn />} errorElement={<RouterError />} />
                  <Route path="/usuaria-busca-cnpj" element={<CnpjInformation />} errorElement={<RouterError />} />
                  <Route path="/logins" element={<Team />} />
                  <Route path="/usuarios" element={<Usuarios />} />
                  <Route path="/usuarios-detail" element={<UsuariosDetail />} />
                  <Route path="/invoices-detail-avc" element={<InvoicesDetailAvc />} />
                  <Route path="/extratos" element={<Extratos />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/avc-x-ons" element={<InvoicesAvcXOns />} />
                  <Route path="/invoices-detail" element={<InvoicesDetail />} />
                  <Route path="/user-form" element={<FormEdit />} />
                  <Route path="/user-create-form" element={<Form />} />
                  <Route path="/faq" element={<FAQ />} /> 
                  <Route path="/inadimplencia" element={<Inadimplencia />} />
                  <Route path="/arquivos" element={<Arquivos />} />
                  <Route path="/arquivos-sistema" element={<ArquivosSite />} />
                  <Route path="/arquivos-site-publico" element={<ArquivosSiteUsuarias />} />
                  <Route path="/arquivo-detail" element={<ArquivosDetalhe />} />
                  <Route path="/user-forgot-password" element={<ForgotPassword />} errorElement={<RouterError />} />
                  <Route path="/404" element={<PageNotFound />} errorElement={<RouterError />} />
                  <Route path="/site" element={<ArquivosSiteUsuarias />} errorElement={<RouterError />} />
                </Routes>
              </Box>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;
