import { Backdrop, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Logins = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiHost = process.env.REACT_APP_API_URL_LOGIN || "http://localhost:8081";
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const handleClick = (documento) => {
    navigate(`/user-form?documento=${documento}`);
  };

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };


  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        setLoading(true);
        fetch(`${apiHost}/usuario-login/users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }

  }, [navigate, apiHost]);


  const columns = [
    { field: "nome", headerName: "Nome", flex: 1.5 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "telefone",
      headerName: "Telefone",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1
    },

    {
      field: "departamento",
      headerName: "Departamento",
      flex: 1,
    },
    {
      field: "profiles",
      headerName: "Acessos",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.profiles.map((profile) => {
            const access = profile;
            return (
              <Typography color={colors.grey[100]} sx={{ ml: "10px" }}>
                {access}
              </Typography>
            );
          })}
        </Typography>
      ),
    },
    {
      field: "documento",
      headerName: "Editar",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleClick(params.row.documento)}        >
          Editar
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Logins de Sistema" subtitle="Logins do Sistema" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid rows={data} columns={columns} getRowId={(row) => row.documento} />
      </Box>
    </Box>
  );
};

export default Logins;
