import React, { useEffect, useState, } from 'react';
import { Card, CardContent, Typography, Box, useTheme, Backdrop, CircularProgress, Grid, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CryptoJS from 'crypto-js';

const ArquivoDetalhe = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const apiHostArq = process.env.REACT_APP_API_URL_ARQ || "http://localhost:8082";
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyIdentify = searchParams.get('companyIdentify');
  const ano = searchParams.get('ano');
  const mes = searchParams.get('mes');
  const id = searchParams.get('id');
  const numeroAvc = searchParams.get('numeroAvc');
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();


  const renderField = (label, value) => (
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="body1">
        {value !== null && value !== undefined ? value : 'N/A'}
      </Typography>
    </Grid>
  );

  const renderFieldLink = (label, value, link) => (
    <Grid item xs={12}>
      <Typography variant="h6">{label}:</Typography>

      <Typography variant="body1">
        <Link to={`${link}`}>{value}</Link>
        <IconButton onClick={() => handleDelete(link)} aria-label="delete" color="error">
          <DeleteIcon />
        </IconButton>
      </Typography>

    </Grid>

  );
  const handleDelete = (value) => {
    const confirmDelete = window.confirm("Você tem certeza que deseja deletar este arquivo?");
    if (!confirmDelete) {
      return;
    }
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        fetch(`${apiHostArq}/arquivos/s3/arquivo?arquivo=${value}&id=${data.id}`
          , {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                alert('Sessão expirada. Por favor, faça login novamente.');
                navigate('/');
                return null;
              }
              else if (response.ok) {
                navigate('/arquivos-site');
              }
              return response.json();
            }
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };


  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        fetch(`${apiHost}/service/avc/id?onsCode=1257&ano=${ano}&numeroAvc=${numeroAvc}&mes=${mes}&companyIdentify=${companyIdentify}&id=${id}`,
          {
            headers: {
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [ano, apiHost, companyIdentify, id, mes, navigate, numeroAvc]);

  return (
    <Box m="20px">
      <Header title="Detalhe AVC" subtitle="Detalhe da Cobrança" />
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Box m="40px 0 0 0" height="75vh" sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .no-border-bottom": {
          borderBottom: "none !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {renderField('Meu Número Ons', data?.onsCode)}
              {renderField('Mês', data?.mes)}
              {renderField('Ano', data?.ano)}
              {renderField('Usuária', data.idUsuaria?.idUsuaria)}
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <br />
          <CardContent>
            <Grid container spacing={2}>
              {renderField('Transmissoras', data.transmissoras)}
              {renderField('CNPJ', data.cnpj ? data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5') : "")}
              {renderField('Pis/Pasep', data.pisPasep ? data.pisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Total com Pis/Pasep', data.totalComPisPasep ? data.totalComPisPasep.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Data Parcela 1', data.dataParcela1)}
              {renderField('Data Parcela 2', data.dataParcela2)}
              {renderField('Data Parcela 3', data.dataParcela3)}
              {renderField('Valor Parcela 1', data.valorParcela1 ? data.valorParcela1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Valor Parcela 2', data.valorParcela2 ? data.valorParcela2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Valor Parcela 3', data.valorParcela3 ? data.valorParcela3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "")}
              {renderField('Período Contábil', data.peridoContabil)}
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
          <Grid container spacing={2}>
    {data?.invoice?.nfeDto && (
      <>
        {renderFieldLink('Danfe', data.invoice.nfeDto.nfeKey, data.invoice.nfeDto.hasPdf ? data.invoice.nfeDto.danfeUrl : "")}
      </>
    )}
  </Grid>
  <Grid container spacing={2}>
    {data?.invoice?.nfeDto && (
      <>
        {renderFieldLink('XML', data.invoice.nfeDto.nfeKey, data.invoice.nfeDto.hasXml ? data.invoice.nfeDto.xmlUrl : "")}
      </>
    )}
  </Grid>
  {data?.invoice?.ticketDtoList && data.invoice.ticketDtoList.map((ticket, index) => (
    <Grid item xs={12} key={index}>
      {renderField('Data', ticket.paymentDate)}
      {renderFieldLink('Boleto', ticket.barCode, ticket.url)}
    </Grid>
  ))}
          </CardContent>
        </Card>
      </Box>
    </Box >
  );
};

export default ArquivoDetalhe;
