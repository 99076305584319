import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Autocomplete, Chip } from "@mui/material";
import { Formik, } from "formik"; // Importe o hook useFormikContext
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import CryptoJS from 'crypto-js';



const FormEdit = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initialValues, setValues] = useState({
    nome: '',
    email: '',
    telefone: '',
    senha: '',
    profiles: [],
  });

  const apiHost = process.env.REACT_APP_API_URL_LOGIN || "http://localhost:8081";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const documento = searchParams.get('documento');
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();


  const handleFormSubmit = async (values) => {

    const selectedProfiles = selectedOptions.map(option => option.trim());
    values.profiles = selectedProfiles;

    const storedEncryptedPayload = getFromSession('session');

    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles && !user.profiles.includes('ADMIN')) {
        navigate(`/404`);
      }
      else {
        await fetch(`${apiHost}/usuario-login/users`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(values),
        })
          .then((response) => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
           
            else if (response.ok) {
              setSuccessMessage("Atualizado com Sucesso");
              setTimeout(() => {
                navigate(`/logins`);
              }, 2000);
            }
            else {
              setErrorMessage("Erro ao atualizar usuário");
            }
            return response.json();
          })
          .catch((error) => {
            setErrorMessage("Erro ao atualizar usuário");
            console.error(error)

          })
          .catch(new Error("Erro ao atualizar usuário"));
      }
    }
  };

  const profiles = [
    "ADMIN",
    "DOCUMENTOS",
    "FINANCEIRO",
    "COBRANCA"
  ];

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };


  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles && !user.profiles.includes('ADMIN')) {
        navigate(`/404`);
      }
      else if (user && user.token) {
        fetch(`${apiHost}/usuario-login/users/documento?documento=${documento}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setValues({
              nome: data.nome || '',
              email: data.email || '',
              telefone: data.telefone || '',
              senha: '',
              profiles: data.profiles || [],
            });
            setSelectedOptions(data.profiles || []);
          })
          .catch(error => console.error(error))
      }
    }
  }, [apiHost, documento, navigate]);

  return (
    <Box m="20px">
      <Header title="Edição de Usuário" subtitle={`Editando usuário ${initialValues.nome}`} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nome}
                name="nome"
                error={!!touched.nome && !!errors.nome}
                helperText={touched.nome && errors.nome}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="departamento"
                label="Departamento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.departamento}
                name="departamento"
                error={!!touched.departamento && !!errors.departamento}
                helperText={touched.departamento && errors.departamento}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 2" }}
              />
              <InputMask
                mask="(99) 99999-9999"
                value={values.telefone}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {() => (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Telefone"
                    name="telefone"
                    error={!!touched.telefone && !!errors.telefone}
                    helperText={touched.telefone && errors.telefone}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ gridColumn: "span 2" }}
                  />
                )}
              </InputMask>
              <Box sx={{ gridColumn: "span 2" }}>
                <Autocomplete
                  multiple
                  options={profiles}
                  value={selectedOptions}
                  onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Selecione os Acessos"
                      placeholder="Choose"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Senha"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senha}
                name="senha"
                error={!!touched.senha && !!errors.senha}
                helperText={touched.senha && errors.senha}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="start" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Editar Login
              </Button>
            </Box>
            {successMessage && <div>{successMessage}</div>}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  nome: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  telefone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  senha: yup
    .string()
    .min(8, "Senha deve ter pelo menos 8 caracteres")
});

export default FormEdit;
